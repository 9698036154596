const tratamientos = [
    {
        tratamiento: "Masajes",
        tipos: [
            {
                tipo: "Relajantes",
                zonas: [
                    "Piernas",
                    "Brazos",
                    "Espalda",
                    "Cuerpo entero"
                ],
                videoURL: "https://www.youtube.com/shorts/zjhH_Zs2Lzs"
            },
            {
                tipo: "Descontracturantes",
                zonas: [
                    "Piernas",
                    "Brazos",
                    "Espalda",
                    "Cuerpo entero"
                ]
            },
            {
                tipo: "Deportivos",
                zonas: [
                    "Piernas",
                    "Brazos",
                    "Espalda",
                    "Cuerpo entero"
                ]
            },
            {
                tipo: "Piedras calientes",
                zonas: [
                    "Cuerpo entero"
                ],
                //observaciones: "Sólo en gabinete"
            }
        ]
    },
    {
        tratamiento: "Depilación",
        observaciones: "Sólo masculina",
        tipos: [
            {
                tipo: "Tradicional con cera",
                zonas: [
                    "Axilas",
                    "Pecho",
                    "Abdomen completo",
                    "Tiro abdomen",
                    "Espalda",
                    "Glúteos",
                    "Piernas completas",
                    "Media pierna",
                    "Partes íntimas",
                    "Brazos completos"
                ],
                //observaciones: "Sólo en gabinete"
            },
            {
                tipo: "Roll-On",
                zonas: [
                    "Pecho",
                    "Abdomen completo",
                    "Tiro abdomen",
                    "Espalda",
                    "Glúteos",
                    "Piernas completas",
                    "Media pierna",
                    "Brazos completos"
                ]
            }
        ]
    }
];

export default tratamientos;