import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
//import PlaceIcon from '@mui/icons-material/Place';

import purple from "../assets/purple-background.jpg";
//import { direccion } from "../vars";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        px: 10,
        pt: 10,
        pb: 2,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${purple})`,
      }}
      component="footer"
    >
      <Typography variant="h3" align="center" gutterBottom color="white">
        Sergio
      </Typography>
      <Typography variant="h6" align="center" gutterBottom color="white">
        Tratamientos de relajación y cuidado personal
      </Typography>
      <Typography
        variant="subtitle1"
        component="p"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="center"
        color="white"
        align="center"
      >
        {/*<PlaceIcon></PlaceIcon>*/}
        {/*direccion*/}
      </Typography>
      <Typography variant="body2" align="center" color="white" sx={{ mt: 10 }}>
        {"Powered by "}
        <Link color="inherit" href="https://ebt.ar/">
          El Bloque Tecno
        </Link>
      </Typography>
    </Box>
  );
};
export default Footer;
