import { Box, Button, Typography } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import { direccion } from "../vars";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { useEffect, useRef } from "react";

const Ubicacion = () => {
  const position = [-34.576085288344714, -60.96008700024583];
  const markerRef = useRef(null);

  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
    });
  }, []);

  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.openPopup();
    }
  }, [markerRef]);

  return (
    <>
      <Typography variant="h2" align="center" color="primary">
        ¿Dónde estamos?
      </Typography>
      {/*<Typography gutterBottom variant="h5" component="div" align="center">
        Podés venir a nuestro gabinete, o vamos a la comodidad de tu casa!
      </Typography>*/}
      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        align="center"
      >
        Estamos ubicados en {direccion}.
      </Typography>
      <Button
        variant="contained"
        size="large"
        endIcon={<DirectionsIcon />}
        component="a"
        target="_blank"
        href="https://maps.app.goo.gl/gsFjduM57EnQHgZFA"
        sx={{ mt: 2 }}
      >
        ¿Cómo llegar?
      </Button>
      <Box sx={{ height: "500px", width: "100%", pt: 2 }}>
        <MapContainer
          center={position}
          zoom={16}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} ref={markerRef}>
            <Tooltip permanent>
              <span>{direccion}</span>
            </Tooltip>
          </Marker>
        </MapContainer>
      </Box>
    </>
  );
};

export default Ubicacion;
